<template>
  <div class="">
    <b-row class="mt-3 mt-md-6">
      <b-col cols="12" md="6" offset-md="3" lg="4" offset-lg="4">
        <b-card no-body>
          <b-card-header>
            <b-card-title class="text-center">
              <h1 class="text-success text-capitalize">Kết quả thanh toán học phí</h1>
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <p class="text-primary">Trạng thái: <strong class="text-success">{{getStatus(payment || null)}}</strong></p>
            <p class="text-primary">Ngày thu: <strong class="text-success">{{getFormattedDate(payment.payDate || '')}}</strong></p>
            <p class="text-primary">Tổng tiền: <strong class="text-success">{{showCurrencyVND(payment.amount || null)}}</strong></p>
            <p class="text-primary">Mã phiếu thu: <strong class="text-success">{{ payment.merchantOrderId || '' }}</strong></p>
            <p class="text-primary">Phương thức thu: <strong class="text-success">{{ payment.paymentMethod || '' }}</strong></p>
            <p class="text-primary">Nội dung thu: <strong class="text-success">{{payment.description || ''}}</strong></p>
          </b-card-body>
          <b-card-footer class="text-center pb-1">
            <b-button variant="primary" @click="printPayment()" >
              In
            </b-button>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton, BCard, BCardBody, BCardFooter, BCardHeader, BCardTitle, BCol, BRow,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUser } from '@/auth/utils'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'

export default {
  name: 'PaymentReturn',
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardFooter,
    BButton,
  },
  data() {
    return {
      isLoading: false,
      user: getUser(),
      showButton: false,
    }
  },
  computed: {
    ...mapGetters({
      payment: 'payment/paymentByUrl',
    }),
  },
  async created() {
    const request = {
      orderId: this.$route.query.data,
      responseCode: this.$route.query.vnptpayResponseCode,
      secureCode: this.$route.query.secureCode,
    }
    await this.getPayment(request)
  },
  methods: {
    ...mapActions({
      getPayment: 'payment/getPaymentByReturnUrl',
    }),
    showCurrencyVND(value) {
      // Create our number formatter.
      const formatter = new Intl.NumberFormat('vi-VN', {
        style: 'currency',
        currency: 'VND',

        // These options are needed to round to whole numbers if that's what you want.
        // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      })

      return formatter.format(value)
    },

    printPayment() {
      window.print()
    },

    getFormattedDate(dateString) {
      if (dateString) { return (dateString) ? moment(dateString, 'YYYYMMDDHHmmss').format('HH:mm:ss DD/MM/yyyy') : '' }
      return ''
    },
    getStatus(data) {
      if (data) { return (data.status === 0) ? data.responseDescription : data.errorDetail || '' }
      return ''
    },
  },
}
</script>

<style lang="scss">
</style>
